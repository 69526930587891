import type { LazyExoticComponent } from 'react';
import { lazy } from 'react';

// Lazy loaded modules.

const EditStudentDetails = lazy(() => import('../pages/admin/forms/studentDetails/edit'));
const ManageStudentDetails = lazy(() => import('../pages/admin/forms/studentDetails/manage'));
const PreviewStudentDetails = lazy(() => import('../pages/admin/forms/studentDetails/preview'));
const EditHealthDetailsForm = lazy(() => import('../pages/admin/forms/healthDetails/edit'));
const ManageHealthDetailsForm = lazy(() => import('../pages/admin/forms/healthDetails/manage'));
const PreviewHealthDetailsForm = lazy(() => import('../pages/admin/forms/healthDetails/preview'));
const EditSectionsFields = lazy(() => import('../pages/admin/forms/healthDetails/sections/edit'));
const ManageSections = lazy(() => import('../pages/admin/forms/healthDetails/sections/manage'));

interface RouteType {
	exact?: boolean;
	path?: string;
	component: LazyExoticComponent<() => JSX.Element>;
	key: string;
}

const FormRoutes: Array<RouteType> = [
	{
		component: EditStudentDetails,
		key: 'edit-student-details',
		path: '/forms/student-details/edit/:id'
	},
	{
		component: ManageStudentDetails,
		key: 'manage-student-details',
		path: '/forms/student-details/manage/:school'
	},
	{
		component: PreviewStudentDetails,
		key: 'preview-student-details',
		path: '/forms/student-details/preview/:school'
	},
	{
		component: EditHealthDetailsForm,
		key: 'edit-health-details',
		path: '/forms/health-details/edit/:id'
	},
	{
		component: ManageHealthDetailsForm,
		key: 'manage-health-details',
		path: '/forms/health-details/manage/:school'
	},
	{
		component: PreviewHealthDetailsForm,
		key: 'preview-health-details',
		path: '/forms/health-details/preview/:school'
	},
	{
		component: EditSectionsFields,
		key: 'edit-sections-fields',
		path: '/forms/health-details/section/edit/:id'
	},
	{
		component: ManageSections,
		key: 'manage-sections',
		path: '/forms/health-details/section/manage/:id'
	}
];

export default FormRoutes;
