import type { FC } from 'react';
import React, { createContext, useState } from 'react';
import type { Paths } from './Sidebar';

export const ActiveContext = createContext({
	active: 'Home' as Paths,
	setActive: (path: Paths) => {}
});

const ActiveProvider: FC = ({ children }) => {
	const [active, setActive] = useState<Paths>('Home');
	return (
		<ActiveContext.Provider value={{ active, setActive: setActive as () => {} }}>{children}</ActiveContext.Provider>
	);
};

export default ActiveProvider;
