import type { LazyExoticComponent } from 'react';
import { lazy } from 'react';

// Lazy loaded modules.
const AddStudent = lazy(() => import('../pages/staff/student/add'));
const EditStudent = lazy(() => import('../pages/staff/student/edit'));
const ManageStudents = lazy(() => import('../pages/staff/student/manage'));
const StudentInfo = lazy(() => import('../pages/staff/student/info'));
const AddHealthDetails = lazy(() => import('../pages/staff/student/healthCard'));

interface RouteType {
	exact?: boolean;
	path?: string;
	component: LazyExoticComponent<() => JSX.Element>;
	key: string;
}

const StudentRoutes: Array<RouteType> = [
	{
		component: AddStudent,
		key: 'add-student',
		path: '/students/add'
	},
	{
		component: EditStudent,
		key: 'edit-student',
		path: '/students/edit/:id'
	},
	{
		component: ManageStudents,
		key: 'manage-students',
		path: '/students/manage'
	},
	{
		component: StudentInfo,
		key: 'manage-students',
		path: '/students/info/:id'
	},
	{
		component: AddHealthDetails,
		key: 'add-health-details',
		path: '/students/health-card/:id'
	}
];

export default StudentRoutes;
