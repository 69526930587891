import type { LazyExoticComponent } from 'react';
import { lazy } from 'react';
/*
 * Router modules.
 */
import AuthRoutes from '../routes/auth';
import FacultyRoutes from '../routes/faculty';
import FormRoutes from '../routes/forms';
import InstituteRoutes from '../routes/institutes';
import StaffRoutes from '../routes/staff';
import StudentRoutes from '../routes/students';
import UserRoutes from '../routes/users';

// Lazy loaded components to improve base performance.
const Home = lazy(() => import('../pages/home'));
const Login = lazy(() => import('../pages/auth/login'));
const LoginOtp = lazy(() => import('../pages/auth/loginOtp'));
const EnterOtp = lazy(() => import('../pages/auth/enterOtp'));
const ParentInfo = lazy(() => import('../pages/parent/info'));
const ParentSetPassword = lazy(() => import('../pages/auth/parentSetPassword'));
const Contact = lazy(() => import('../pages/contact'));
const Dashboard = lazy(() => import('../pages/dashboard'));
const AdminDashboard = lazy(() => import('../pages/admin/dashboard'));
const DynamicForm = lazy(() => import('../pages/dynamicForm'));
const Error = lazy(() => import('../pages/error'));

const Alerts = lazy(() => import('../pages/facultyadmin/student/alerts'));
const ContactDash = lazy(() => import('../pages/misc/contact-us'));
const CopyFields = lazy(() => import('../pages/misc/copyField'));
const CopyHealthFields = lazy(() => import('../pages/misc/copyHealthField'));
const CopyFromSchools = lazy(() => import('../pages/misc/copyFromSchools'));
const Misc = lazy(() => import('../pages/misc/misc'));
// const CopySections = lazy(() => import('../pages/misc/copySection'));

interface RouteType {
	exact?: boolean;
	path?: string;
	component: LazyExoticComponent<() => JSX.Element>;
	key: string;
}

const routes: Array<RouteType> = [
	{
		component: Home,
		key: 'home',
		exact: true,
		path: '/'
	},
	{
		component: Login,
		key: 'login',
		exact: true,
		path: '/login'
	},
	{
		component: LoginOtp,
		key: 'login-otp',
		exact: true,
		path: '/login-password'
	},
	{
		component: EnterOtp,
		key: 'enter-otp',
		exact: true,
		path: '/enter-otp/:number'
	},
	{
		component: ParentInfo,
		key: 'parent-info',
		exact: true,
		path: '/parent/info'
	},
	{
		component: ParentSetPassword,
		key: 'parent-set-password',
		exact: true,
		path: '/parent/set-password'
	},
	{
		component: Alerts,
		key: 'alerts',
		exact: false,
		path: '/alerts'
	},
	{
		component: Contact,
		key: 'contact',
		path: '/contact'
	},
	{
		component: ContactDash,
		key: 'contact',
		path: '/contact-us'
	},
	...AuthRoutes,
	...FormRoutes,
	...InstituteRoutes,
	...StaffRoutes,
	...StudentRoutes,
	...UserRoutes,
	...FacultyRoutes,
	{
		component: Dashboard,
		key: 'dashboard',
		path: '/dashboard'
	},
	{
		component: AdminDashboard,
		key: 'admin-dashboard',
		path: '/admin/dashboard'
	},
	{
		component: DynamicForm,
		key: 'dynamic-form',
		path: '/form'
	},
	{
		component: CopyFromSchools,
		key: 'copy-from-school',
		path: '/copy/schools/:forSchool?/:formtype'
	},
	{
		component: CopyFields,
		key: 'copy-field',
		path: '/copy/:forSchool?/:school/:formtype',
		exact: true
	},
	{
		component: CopyHealthFields,
		key: 'copy-health-field',
		path: '/copy/:school/:forSchool?/:formtype/:sectionId'
	},
	{
		component: Misc,
		key: 'add-misc-details',
		path: '/addMiscDetails/:id'
	},
	{
		component: Error,
		key: 'error'
	}
];

export default routes;
