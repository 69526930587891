import type { LazyExoticComponent } from 'react';
import { lazy } from 'react';

// Lazy loaded modules.
const EditUser = lazy(() => import('../pages/admin/users/edit'));
const ManageUsers = lazy(() => import('../pages/admin/users/manage'));

interface RouteType {
	exact?: boolean;
	path?: string;
	component: LazyExoticComponent<() => JSX.Element>;
	key: string;
}

const UserRoutes: Array<RouteType> = [
	{
		component: EditUser,
		key: 'edit-user',
		path: '/users/edit/:id'
	},
	{
		component: ManageUsers,
		key: 'manage-users',
		path: '/users/manage'
	}
];

export default UserRoutes;
