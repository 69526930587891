import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from '../logo.svg';
import { ActiveContext } from './ActiveProvider';
import { LoginContext } from './LoginProvider';

const Header = () => {
	const ctx = useContext(LoginContext);
	const { active } = useContext(ActiveContext);
	// useEffect(() => {
	// 	closeNav();
	// });

	let toggle = 0;
	const openNav = () => {
		const sidebar = document.getElementById('sidebar') as HTMLElement;
		if (!sidebar) {
			return;
		}
		const body = document.body as HTMLElement;
		if (toggle === 0) {
			sidebar.style.transform = 'translateY(-50%) translateX(00px)';
			body.style.overflow = 'hidden';
			toggle = 1;
		} else if (toggle === 1) {
			sidebar.style.transform = 'translateY(-50%) translateX(-110px)';
			body.style.overflow = 'auto';
			toggle = 0;
		}
	};
	const closeNav = () => {
		const sidebar = document.getElementById('sidebar') as HTMLElement;
		if (!sidebar) {
			return;
		}
		const body = document.body as HTMLElement;
		if (sidebar) {
			sidebar.style.transform = 'translateY(-50%) translateX(-110px)';
			body.style.overflow = 'auto';
			toggle = 0;
		}
	};

	return (
		<Fragment>
			{active !== 'Login' && (
				<div className="sticky-top">
					<nav className='navbar navbar-expand-lg navbar-light bg-white shadow-sm mb-3'>
						<div className='container-fluid'>
							<Link className='navbar-brand' id='nav-opener-brand' to={{ pathname: '/' }}>
								<div>
									<img src={logo} alt='HealthCard' style={{ height: '30px' }} />
									&nbsp;&nbsp; HealthCard
								</div>
							</Link>
							<button
								onClick={openNav}
								className='navbar-toggler'
								type='button'
								aria-controls='navbarNav'
								aria-expanded='false'
								aria-label='Toggle navigation'
							>
								<span className='navbar-toggler-icon'></span>
							</button>
							<div className='collapse navbar-collapse' id='navbarNav'>
								<ul className='navbar-nav ms-auto'>
									{!ctx.token && (
										<li className='nav-item'>
											<Link to={{ pathname: '/login' }} className='nav-link'>
												<i
													className='fas fa-user text-secondary me-2'
													style={{ fontSize: '1.25em' }}
												></i> Login
											</Link>
										</li>
									)}
									{ctx.token && (
										<li className='nav-item dropdown'>
											<a
												className='nav-link dropdown-toggle active'
												href='/'
												id='navbarDropdownMenuLink'
												role='button'
												data-bs-toggle='dropdown'
												aria-expanded='false'
											>
												<i
													style={{ fontSize: '1.25em' }}
													className='text-secondary fas fa-user-cog'
												></i>
											</a>
											<ul
												className='dropdown-menu dropdown-menu-end'
												aria-labelledby='navbarDropdownMenuLink'
											>
												<li>
													<a className='dropdown-item' href='/reset-password'>
														<i className='fas fa-lock'></i> Reset Password
													</a>
												</li>
												<li>
													<button
														className='dropdown-item'
														type='button'
														onClick={() => {
															ctx.setRole(undefined);
															ctx.setToken(undefined);
															ctx.setExpires(undefined);
														}}
													>
														<i className='fas fa-sign-out-alt'></i> Logout
													</button>
												</li>
											</ul>
										</li>
									)}
								</ul>
							</div>
						</div>
					</nav>
				</div>
			)}
		</Fragment>
	);
};

export default Header;
