import type { LazyExoticComponent } from 'react';
import { lazy } from 'react';

// Lazy loaded modules.
const Login = lazy(() => import('../pages/auth/login'));
const ChangePassword = lazy(() => import('../pages/auth/changePassword'));
const ResetPassword = lazy(() => import('../pages/auth/resetPassword'));
const SetPassword = lazy(() => import('../pages/auth/setPassword'));
const ForgotPassword = lazy(() => import('../pages/auth/forgotPassword'));

interface RouteType {
	exact?: boolean;
	path?: string;
	component: LazyExoticComponent<() => JSX.Element>;
	key: string;
}

const AuthRoutes: Array<RouteType> = [
	{
		component: Login,
		key: 'login',
		path: '/login'
	},
	{
		component: ChangePassword,
		key: 'change-password',
		path: '/change-password'
	},
	{
		component: ResetPassword,
		key: 'reset-password',
		path: '/reset-password'
		// exact: true
	},
	// {
	// 	component: ResetPassword,
	// 	key: 'reset-password-token',
	// 	path: '/reset-password/:token'
	// },
	{
		component: SetPassword,
		key: 'set-password-token',
		path: '/set-password'
	},
	{
		component: ForgotPassword,
		key: 'forgot-password',
		path: '/forgot-password'
	}
];

export default AuthRoutes;
