import { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from '../logo.svg';
import '../styles/sidebar.css';
import { ActiveContext } from './ActiveProvider';
import { LoginContext } from './LoginProvider';

export type Paths =
	| 'Home'
	| 'Dashboard'
	| 'Manage Institutes'
	| 'Preview Institute'
	| 'Manage Users'
	| 'Manage Students'
	| 'Login';

export const closeNav = () => {
	const sidebar = document.getElementById('sidebar') as HTMLElement;
	if (!sidebar) {
		return;
	}
	const body = document.body as HTMLElement;
	if (sidebar) {
		const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
		if (vw < 991) {
			sidebar.style.transform = 'translateY(-50%) translateX(-110px)';
			body.style.overflow = 'auto';
		}
	}
};

const Sidebar = () => {
	// This stores the currently active route.
	const { active } = useContext(ActiveContext);
	const ctx = useContext(LoginContext);
	let role = '';
	if (ctx.role === 'facultyadmin') role = 'Admin';
	if (ctx.role === 'admin') role = 'Admin';
	if (ctx.role === 'user') role = 'Staff';
	let links: Array<{ pathname: string; display: string; icon: string }> = [];
	if (ctx.role === 'admin') {
		links = [
			{
				pathname: '/dashboard',
				display: 'Dashboard',
				icon: 'fa fa-tachometer-alt'
			},
			{
				pathname: '/institutes/manage',
				display: 'Manage Institutes',
				icon: 'fa fa-graduation-cap'
			},
			{
				pathname: '/users/manage',
				display: 'Manage Users',
				icon: 'fa fa-users'
			}
		];
	} else if (ctx.role === 'user') {
		links = [
			{
				pathname: '/staff/dashboard',
				display: 'Dashboard',
				icon: 'fa fa-tachometer-alt'
			},
			{
				pathname: '/students/manage',
				display: 'Manage Students',
				icon: 'fa fa-users'
			}
		];
	} else if (ctx.role === 'parent') {
		links = [
			{
				pathname: '/dashboard',
				display: 'Dashboard',
				icon: 'fa fa-tachometer-alt'
			}
		];
	} else if (ctx.role === 'facultyadmin' || ctx.role === 'doctor') {
		links = [
			{
				pathname: '/faculty/dashboard',
				display: 'Dashboard',
				icon: 'fa fa-tachometer-alt'
			},
			{
				pathname: '/faculty/students/manage',
				display: 'Manage Students',
				icon: 'fa fa-users'
			},
			{
				pathname: '/alerts',
				display: 'Student Alerts',
				icon: 'fa fa-user-clock'
			}
		];
	}

	return (
		<Fragment>
			{active !== 'Login' && (
				<div>
					<nav id='sidebar' className=''>
						<div>
							<div>
								<img
									src={logo}
									alt='HealthCard'
									style={{
										height: '30px',
										marginBottom: '30px',
										color: 'white',
										filter: 'invert(1) sepia(1) saturate(5) hue-rotate(175deg)'
									}}
								/>
							</div>
						</div>
						<div>
							{links.map(({ display, pathname, icon }, i) => (
								<Link
									to={{ pathname }}
									key={i}
									className='nav-links'
									onClick={() => {
										closeNav();
									}}
								>
									<div>
										<i className={icon}></i>
										<div className='side-nav-text'>{display}</div>
									</div>
								</Link>
							))}
						</div>
						<div>
							<Link
								to={{ pathname: ctx.role == 'parent' ? '/parent/set-password' : '/change-password' }}
								className='nav-links'
								onClick={() => {
									closeNav();
								}}
							>
								<div>
									<i className='fas fa-lock'></i>
									<div className='side-nav-text'>Change Password</div>
								</div>
							</Link>
							<Link
								to={{ pathname: '/' }}
								className='btn text-center nav-links'
								type='button'
								onClick={() => {
									closeNav();
									ctx.setRole(undefined);
									ctx.setToken(undefined);
									ctx.setExpires(undefined);
									// var iframe = document.getElementById(":1.container") as any;
									// iframe?.contentWindow?.document.getElementById(":1.close")?.click();
								}}
							>
								<i className='fa fa-sign-out-alt'></i>
								<div className='side-nav-text'>Sign Out</div>
							</Link>
						</div>
						<div className='mt-auto mb-0'>
							<a href='/contact-us' className='nav-links'>
								<div>
									<i style={{ fontSize: '25px' }} className='fas fa-envelope text-white'></i>
									<div className='side-nav-text'>Contact Us</div>
								</div>
							</a>
							<button className=' btn btn-sm bg-white'>{role}</button>
						</div>
					</nav>
				</div>
			)}
		</Fragment>
	);
};

export default Sidebar;
