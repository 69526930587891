import { Suspense, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import './styles/App.css';
import routes from './util/routes';

function App() {
	let location = useLocation();
	const history = useHistory();
	const [vw, setVW] = useState(Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0));
	const [visibleSidebar, setVisibleSidebar] = useState(true);
	const [visibleHeader, setVisibleHeader] = useState(true);

	// useEffect(() => {
	// 	window.addEventListener('resize', () => {
	// 		var lclVW = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
	// 		setVW(lclVW);
	// 	});
	// });

	useEffect(() => {
		const main = document.getElementById('main') as HTMLElement;
		const mainContent = document.getElementById('main-content') as HTMLElement;
		if (vw > 991) {
			main.style.marginLeft = '85px';
			main.style.marginRight = '0px';
			mainContent.classList.add('mt-3');
			setVisibleSidebar(true);
			setVisibleHeader(false);
		} else {
			main.style.marginLeft = '0px';
			main.style.marginRight = '0px';
			mainContent.classList.remove('mt-3');
			setVisibleSidebar(true);
			setVisibleHeader(true);
		}
		if (
			location.pathname === '/' ||
			location.pathname === '/contact' ||
			location.pathname === '/login' ||
			location.pathname === '/login-otp' ||
			location.pathname === '/login-password' ||
			location.pathname.startsWith('/enter-otp' as string) ||
			location.pathname === '/forgot-password' ||
			location.pathname === '/reset-password'
		) {
			setVisibleSidebar(false);
			setVisibleHeader(false);
			main.style.marginLeft = '0px';
			main.style.marginRight = '0px';
			mainContent.classList.remove('mt-3');
		}
		history.listen((location) => {
			if (
				!(
					location.pathname === ('/' as string) ||
					location.pathname === ('/contact' as string) ||
					location.pathname === ('/login' as string) ||
					location.pathname === ('/login-otp' as string) ||
					location.pathname === '/login-password' ||
					location.pathname.startsWith('/enter-otp' as string) ||
					location.pathname === ('/forgot-password' as string) ||
					location.pathname === ('/reset-password' as string)
				)
			) {
				setVisibleHeader(false);
				const main = document.getElementById('main') as HTMLElement;
				if (vw > 991) {
					main.style.marginLeft = '85px';
					main.style.marginRight = '0px';
					mainContent.classList.add('mt-3');
					setVisibleSidebar(true);
					setVisibleHeader(false);
				} else {
					main.style.marginLeft = '0px';
					main.style.marginRight = '0px';
					mainContent.classList.remove('mt-3');
					setVisibleSidebar(true);
					setVisibleHeader(true);
				}
			} else {
				setVisibleSidebar(false);
				setVisibleHeader(false);
			}
		});
	}, [history, location.pathname, vw]);
	return (
		<div className='App'>
			<main id='main' className='d-flex flex-column' style={{ minHeight: '100vh', position: 'relative' }}>
				{/* <Sidebar /> */}
				{visibleSidebar === true && <Sidebar />}
				{visibleHeader === true && <Header />}
				{/* {(location.pathname === '/' || vw < 991) && <Header />} */}
				<div id='main-content' className={`main-content mt-3 ${visibleHeader ? 'mx-md-4' : 'mx-4'}`}>
					{visibleHeader !== true && (
						<div className='content p-0 mt-0'>
							<Suspense
								fallback={
									<div style={{ marginTop: '40vh', textAlign: 'center' }}>
										<div className='loader'></div>
									</div>
								}
							>
								<Switch>
									{routes.map((route) => (
										<Route {...route} />
									))}
								</Switch>
							</Suspense>
						</div>
					)}
					{visibleHeader === true && (
						<div className='content p-2 mt-1'>
							<Suspense
								fallback={
									<div style={{ marginTop: '40vh', textAlign: 'center' }}>
										<div className='loader'></div>
									</div>
								}
							>
								<Switch>
									{routes.map((route) => (
										<Route {...route} />
									))}
								</Switch>
							</Suspense>
						</div>
					)}
				</div>
				{visibleSidebar === true && <Footer />}
			</main>
		</div>
	);
}

export default App;
