import type { LazyExoticComponent } from 'react';
import { lazy } from 'react';

const StaffDashboard = lazy(() => import('../pages/staff/dashboard'));

interface RouteType {
	exact?: boolean;
	path?: string;
	component: LazyExoticComponent<() => JSX.Element>;
	key: string;
}

const StaffRoutes: Array<RouteType> = [
	{
		component: StaffDashboard,
		key: 'staff-dashboard',
		path: '/staff/dashboard'
	},
];

export default StaffRoutes;
