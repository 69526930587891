import type { LazyExoticComponent } from 'react';
import { lazy } from 'react';

// Lazy loaded modules.
const EditInstitute = lazy(() => import('../pages/admin/institutes/edit'));
const ManageInstitutes = lazy(() => import('../pages/admin/institutes/manage'));
const PreviewInstitute = lazy(() => import('../pages/admin/institutes/preview'));
const EditInstituteUsers = lazy(() => import('../pages/admin/institutes/users/edit'));
const Manage = lazy(() => import('../pages/admin/institutes/dashboard/manage'));
const Info = lazy(() => import('../pages/admin/institutes/dashboard/info'));
const Graphs = lazy(() => import('../pages/admin/institutes/graphs'));

interface RouteType {
	exact?: boolean;
	path?: string;
	component: LazyExoticComponent<() => JSX.Element>;
	key: string;
}

const InstituteRoutes: Array<RouteType> = [
	{
		component: EditInstitute,
		key: 'edit-institute',
		path: '/institutes/edit/:id'
	},
	{
		component: ManageInstitutes,
		key: 'manage-institutes',
		path: '/institutes/manage'
	},
	{
		component: PreviewInstitute,
		key: 'preview-institute',
		path: '/institutes/preview/:id'
	},
	{
		component: EditInstituteUsers,
		key: 'edit-institute-users',
		path: '/institutes/users/edit/:id'
	},
	{
		component: Manage,
		key: 'manage-students',
		path: '/institutes/dashboard/manage/:id'
	},
	{
		component: Info,
		key: 'info',
		path: '/institutes/dashboard/info/:id'
	},
	{
		component: Graphs,
		key: 'graphs',
		path: '/institutes/graphs/:id'
	}
];

export default InstituteRoutes;
