import { Link } from 'react-router-dom';
import somaiyaLogo from '../somaiya-trust.jpeg';
import '../styles/sidebar.css';
import svv from '../svv.png';

const Footer = () => {
	return (
		<>
			<footer className='footer mt-auto mb-0' style={{ position: 'relative' }}>
				<div className='border-top shadow '>
					<div className='container col-md-10 col-12  d-flex flex-wrap align-items-center justify-content-between p-4'>
						<div className='mb-4 mb-md-0  me-0 me-md-3 homepageIcons'>
							<img src={svv} alt='HealthCard' style={{ width: '100%' }} />
						</div>
						<div className='mb-4 mb-md-0 brand-image  homepageIcons text-end notOnDesktop'>
							<img src={somaiyaLogo} alt='HealthCard' style={{ width: '100%' }} />
						</div>
						<div className='text-center'>
							<div>
								<b>Developed and Maintained By</b>
							</div>
							<div className='text-small'>K. J. Somaiya Institute of Technology, Sion, Mumbai</div>
							<div style={{ fontSize: '20px' }} className='text-dark my-3 mt-md-0'>
								<a
									target='_blank'
									style={{ color: 'var(--accent-light)' }}
									className='text-decoration:none'
									rel='noreferrer'
									href='http://kjsieit.somaiya.edu'
								>
									<i className='fa fa-globe me-3'></i>
								</a>
								<a
									target='_blank'
									style={{ color: 'var(--accent-light)' }}
									className='text-decoration:none'
									rel='noreferrer'
									href='https://www.facebook.com/kjsieitofficial'
								>
									<i className='fab fa-facebook me-3'></i>
								</a>
								<a
									target='_blank'
									style={{ color: 'var(--accent-light)' }}
									className='text-decoration:none'
									rel='noreferrer'
									href='https://www.instagram.com/kjsieit_22/'
								>
									<i className='fab fa-instagram   me-3  '></i>
								</a>
								<a
									target='_blank'
									style={{ color: 'var(--accent-light)' }}
									className='text-decoration:none'
									rel='noreferrer'
									href='https://twitter.com/kjsieit1'
								>
									<i className='fab fa-twitter   me-3  '></i>
								</a>
								<a
									target='_blank'
									style={{ color: 'var(--accent-light)' }}
									className='text-decoration:none'
									rel='noreferrer'
									href='https://www.youtube.com/kjsieitofficial'
								>
									<i className='fab fa-youtube    '></i>
								</a>
							</div>
							<div className='mt-auto mb-0 text-center '>
								<Link to='/contact-us'>Know About Us</Link>
							</div>
						</div>
						<div className='brand-image  homepageIcons text-end notOnPhone'>
							<img src={somaiyaLogo} alt='HealthCard' style={{ width: '100%' }} />
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
