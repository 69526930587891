import type { FC } from 'react';
import React, { createContext, useEffect, useState } from 'react';

export type Roles = 'guest' | 'facultyadmin' | 'admin' | 'user' | 'parent' | 'doctor' | undefined;

export interface CTX {
	role: Roles;
	setRole: (role: Roles) => any;
	token?: string;
	setToken: (token: string | undefined) => any;
	expires?: Date;
	setExpires: (date: Date | undefined) => any;
	school?: string | undefined;
	setSchool: (school: string | undefined) => any;
	student?: any;
	setStudent: (student: string | undefined) => any;
}

export const LoginContext = createContext({
	role: undefined as Roles,
	setRole: (_role: Roles) => {},
	token: undefined as string | undefined,
	setToken: (_token?: string) => {},
	expires: undefined as Date | undefined,
	setExpires: (_date: Date | undefined) => {},
	school: undefined as string | undefined,
	setSchool: (_school: string | undefined) => {},
	student: undefined as string | undefined,
	setStudent: (_student: string | undefined) => {}
});

const LoginProvider: FC = ({ children }) => {
	const [role, setRole] = useState<Roles>('guest');
	const [token, setToken] = useState<string | undefined>(undefined);
	const [expires, setExpires] = useState<Date | undefined>(undefined);
	const [school, setSchool] = useState<string | undefined>(undefined);
	const [student, setStudent] = useState<string | undefined>(undefined);

	const saveRole = (role: Roles) => {
		if (role) window.localStorage.setItem('role', role);
		else window.localStorage.removeItem('role');
		setRole(role);
	};

	const saveToken = (token: string | undefined) => {
		if (token) window.localStorage.setItem('token', token);
		else window.localStorage.removeItem('token');
		setToken(token);
	};

	const saveExpires = (date: Date | undefined) => {
		const dateObj = new Date(date ?? '');
		if (date) window.localStorage.setItem('expires', dateObj.toString());
		else window.localStorage.removeItem('expires');
		setExpires(date);
	};

	const saveSchool = (school: string | undefined) => {
		if (school) window.localStorage.setItem('school', school);
		else window.localStorage.removeItem('school');
		setSchool(school);
	};
	const saveStudent = (student: string | undefined) => {
		if (student) window.localStorage.setItem('student', student);
		else window.localStorage.removeItem('student');
		setStudent(student);
	};

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			const date = new Date(window.localStorage.getItem('expires') ?? 'Jan 1 2021').getTime();
			if (date > Date.now()) {
				setExpires(new Date(date));
				setToken(window.localStorage.getItem('token') as string | undefined);
				setRole(window.localStorage.getItem('role') as Roles);
				setSchool(window.localStorage.getItem('school') as string | undefined);
				setStudent(window.localStorage.getItem('student') as string | undefined);
			}
		}

		return () => {
			mounted = false;
		};
	}, []);

	return (
		<LoginContext.Provider
			value={{
				role,
				setRole: saveRole,
				setToken: saveToken,
				token,
				expires,
				setExpires: saveExpires,
				school,
				setSchool: saveSchool,
				student,
				setStudent: saveStudent
			}}
		>
			{children}
		</LoginContext.Provider>
	);
};

export default LoginProvider;
