import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ActiveProvider from './components/ActiveProvider';
import LoginProvider from './components/LoginProvider';

ReactDOM.render(
	<BrowserRouter>
		<React.StrictMode>
			<LoginProvider>
				<ActiveProvider>
					<App />
				</ActiveProvider>
			</LoginProvider>
		</React.StrictMode>
	</BrowserRouter>,
	document.getElementById('root')
);
