import type { LazyExoticComponent } from 'react';
import { lazy } from 'react';

const EditInstitute = lazy(() => import('../pages/facultyadmin/institutes/edit'));
const Graphs = lazy(() => import('../pages/facultyadmin/institutes/graphs'));
const HCGraphs = lazy(() => import('../pages/facultyadmin/institutes/hcgraphs'));
const EditInstituteUsers  = lazy(() => import('../pages/facultyadmin/institutes/users/edit'));

const AddStudent = lazy(() => import('../pages/facultyadmin/student/add'));
const EditStudent = lazy(() => import('../pages/facultyadmin/student/edit'));
const HealthCardDetails = lazy(() => import('../pages/facultyadmin/student/healthCard'));
const StudentInfo = lazy(() => import('../pages/facultyadmin/student/info'));
const ManageStudents = lazy(() => import('../pages/facultyadmin/student/manage'));

const FacultyDashboard = lazy(() => import('../pages/facultyadmin/dashboard'));

const EditStudentDetails = lazy(() => import('../pages/facultyadmin/forms/studentDetails/edit'));
const ManageStudentDetails = lazy(() => import('../pages/facultyadmin/forms/studentDetails/manage'));
const PreviewStudentDetails = lazy(() => import('../pages/facultyadmin/forms/studentDetails/preview'));
const EditHealthDetailsForm = lazy(() => import('../pages/facultyadmin/forms/healthDetails/edit'));
const ManageHealthDetailsForm = lazy(() => import('../pages/facultyadmin/forms/healthDetails/manage'));
const PreviewHealthDetailsForm = lazy(() => import('../pages/facultyadmin/forms/healthDetails/preview'));
const EditSectionsFields = lazy(() => import('../pages/facultyadmin/forms/healthDetails/sections/edit'));
const ManageSections = lazy(() => import('../pages/facultyadmin/forms/healthDetails/sections/manage'));

interface RouteType {
	exact?: boolean;
	path?: string;
	component: LazyExoticComponent<() => JSX.Element>;
	key: string;
}

const FacultyRoutes: Array<RouteType> = [
	{
		component: FacultyDashboard,
		key: 'staff-dashboard',
		path: '/faculty/dashboard'
	},
	{
		component: AddStudent,
		key: 'add-student',
		path: '/faculty/students/add'
	},
	{
		component: EditStudent,
		key: 'edit-student',
		path: '/faculty/students/edit/:id'
	},
	{
		component: ManageStudents,
		key: 'manage-students',
		path: '/faculty/students/manage'
	},
	{
		component: HealthCardDetails,
		key: 'healthcard-details',
		path: '/faculty/students/health-card/:id'
	},
	{
		component: StudentInfo,
		key: 'manage-students',
		path: '/faculty/students/info/:id'
	},
    {
		component: EditInstitute,
		key: 'edit-institute',
		path: '/faculty/institutes/edit/:id'
	},
    {
		component: Graphs,
		key: 'graphs-institute',
		path: '/faculty/institutes/graphs'
	},
    {
		component: HCGraphs,
		key: 'graphs-hc',
		path: '/faculty/institutes/hcgraphs'
	},
	{
		component: EditInstituteUsers,
		key: 'edit-institute-users',
		path: '/faculty/institutes/users/edit/:id'
    },
	{
		component: EditStudentDetails,
		key: 'edit-student-details',
		path: '/faculty/forms/student-details/edit/:id'
	},
	{
		component: ManageStudentDetails,
		key: 'manage-student-details',
		path: '/faculty/forms/student-details/manage/:school'
	},
	{
		component: PreviewStudentDetails,
		key: 'preview-student-details',
		path: '/faculty/forms/student-details/preview'
	},
	{
		component: EditHealthDetailsForm,
		key: 'edit-health-details',
		path: '/faculty/forms/health-details/edit/:id'
	},
	{
		component: ManageHealthDetailsForm,
		key: 'manage-health-details',
		path: '/faculty/forms/health-details/manage/:school'
	},
	{
		component: PreviewHealthDetailsForm,
		key: 'preview-health-details',
		path: '/faculty/forms/health-details/preview'
	},
	{
		component: EditSectionsFields,
		key: 'edit-sections-fields',
		path: '/faculty/forms/health-details/section/edit/:id'
	},
	{
		component: ManageSections,
		key: 'manage-sections',
		path: '/faculty/forms/health-details/section/manage/:id'
	}
];

export default FacultyRoutes;
